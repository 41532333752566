import * as _defineProperties2 from "define-properties";
var _defineProperties = "default" in _defineProperties2 ? _defineProperties2.default : _defineProperties2;
import * as _callBind2 from "call-bind";
var _callBind = "default" in _callBind2 ? _callBind2.default : _callBind2;
import _implementation from "./implementation";
import _polyfill from "./polyfill";
import _shim from "./shim";
var exports = {};
var define = _defineProperties;
var callBind = _callBind;
var implementation = _implementation;
var getPolyfill = _polyfill;
var shim = _shim;
var flagsBound = callBind(getPolyfill());
define(flagsBound, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
exports = flagsBound;
export default exports;