import * as _setFunctionName2 from "set-function-name";
var _setFunctionName = "default" in _setFunctionName2 ? _setFunctionName2.default : _setFunctionName2;
var exports = {};
var setFunctionName = _setFunctionName;
var $Object = Object;
var $TypeError = TypeError;
exports = setFunctionName(function flags() {
  if (this != null && this !== $Object(this)) {
    throw new $TypeError("RegExp.prototype.flags getter called on non-object");
  }
  var result = "";
  if (this.hasIndices) {
    result += "d";
  }
  if (this.global) {
    result += "g";
  }
  if (this.ignoreCase) {
    result += "i";
  }
  if (this.multiline) {
    result += "m";
  }
  if (this.dotAll) {
    result += "s";
  }
  if (this.unicode) {
    result += "u";
  }
  if (this.unicodeSets) {
    result += "v";
  }
  if (this.sticky) {
    result += "y";
  }
  return result;
}, "get flags", true);
export default exports;